/*! --------------------------------------------------------------------
JAVASCRIPT "Burocratik's plugins - must have"

* @Version:    2.0.0 - 2014
* @author:     Burocratik (alexandre gomes - @alexrgomes)
* @email:      alex@burocratik.com, hello@burocratik.com
* @website:    http://www.burocratik.com
* @preserve
-----------------------------------------------------------------------*/

/*-----------------------------------------------------------------------
 * jQuery doTimeout: Like setTimeout, but better! - v1.0 - 3/3/2010
 * http://benalman.com/projects/jquery-dotimeout-plugin/
 -----------------------------------------------------------------------*/
(function($){var a={},c="doTimeout",d=Array.prototype.slice;$[c]=function(){return b.apply(window,[0].concat(d.call(arguments)))};$.fn[c]=function(){var f=d.call(arguments),e=b.apply(this,[c+f[0]].concat(f));return typeof f[0]==="number"||typeof f[1]==="number"?this:e};function b(l){var m=this,h,k={},g=l?$.fn:$,n=arguments,i=4,f=n[1],j=n[2],p=n[3];if(typeof f!=="string"){i--;f=l=0;j=n[1];p=n[2]}if(l){h=m.eq(0);h.data(l,k=h.data(l)||{})}else{if(f){k=a[f]||(a[f]={})}}k.id&&clearTimeout(k.id);delete k.id;function e(){if(l){h.removeData(l)}else{if(f){delete a[f]}}}function o(){k.id=setTimeout(function(){k.fn()},j)}if(p){k.fn=function(q){if(typeof p==="string"){p=g[p]}p.apply(m,d.call(n,i))===true&&!q?o():e()};o()}else{if(k.fn){j===undefined?e():k.fn(j===false);return true}else{e()}}}})(jQuery);

/*-----------------------------------------------------------------------
 * jQuery throttle / debounce - v1.1 - 3/7/2010
 * http://benalman.com/projects/jquery-throttle-debounce-plugin/
 -----------------------------------------------------------------------*/
(function(b,c){var $=b.jQuery||b.Cowboy||(b.Cowboy={}),a;$.throttle=a=function(e,f,j,i){var h,d=0;if(typeof f!=="boolean"){i=j;j=f;f=c}function g(){var o=this,m=+new Date()-d,n=arguments;function l(){d=+new Date();j.apply(o,n)}function k(){h=c}if(i&&!h){l()}h&&clearTimeout(h);if(i===c&&m>e){l()}else{if(f!==true){h=setTimeout(i?k:l,i===c?e-m:e)}}}if($.guid){g.guid=j.guid=j.guid||$.guid++}return g};$.debounce=function(d,e,f){return f===c?a(d,e,false):a(d,f,e!==false)}})(this);

/*-----------------------------------------------------------------------
 * jQuery outside events - v1.1 - 3/16/2010
 * http://benalman.com/projects/jquery-outside-events-plugin/
 -----------------------------------------------------------------------*/
(function($,c,b){$.map("click dblclick mousemove mousedown mouseup mouseover mouseout change select submit keydown keypress keyup".split(" "),function(d){a(d)});a("focusin","focus"+b);a("focusout","blur"+b);$.addOutsideEvent=a;function a(g,e){e=e||g+b;var d=$(),h=g+"."+e+"-special-event";$.event.special[e]={setup:function(){d=d.add(this);if(d.length===1){$(c).bind(h,f)}},teardown:function(){d=d.not(this);if(d.length===0){$(c).unbind(h)}},add:function(i){var j=i.handler;i.handler=function(l,k){l.target=k;j.apply(this,arguments)}}};function f(i){$(d).each(function(){var j=$(this);if(this!==i.target&&!j.has(i.target).length){j.triggerHandler(e,[i.target])}})}}})(jQuery,document,"outside");



/********************************************************************************************
 **                                                                                       **
     =RESIZE IMAGES (live background: cover AND  object-fit: cover;)
      1. can work as a fallback for no-object-fit.
      2. needs a container, and not browser with and height (this way is more generic)
      3. test for vertical images (in responsive i can't get horiginal size of the pics)
 **                                                                                       **
*********************************************************************************************/
function resizeLikeCover($element){
    $element.each(function(){
        var $this = $(this);
        var picW = $this.width(),
            picH = $this.height(),
            picWH = picW/picH;
        var $picParent = $this.parent(),
             parentW = $picParent.width(),
             parentH = $picParent.height(),
             parentWH = parentW/parentH;
        if(parentW>parentH && parentWH>=picWH) {
          $this.css({width:parentW, height: "auto"});
          var difH=parseInt($this.css("height"))-parentH; //Centrar fotos - Y
          var auxH=parseInt(difH/2);
          $this.css({"top": -auxH, "left":0});

        } else {
          $this.css({width:"auto", height: parentH});
          var difW=parseInt($this.css("width"))-parentW; //Centrar fotos - X
          var auxW=parseInt(difW/2);
          $this.css({"left": -auxW, "top":0});
        }
    });//end each
}//end function resize